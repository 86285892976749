




import { Component, Vue } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/emission-report/emission-report";
import configEmissionReportFactory from "App/config-emission-report";

@Component
export default class EmissionReportWrapper extends Vue {
  currentVesselId: number | undefined;
  instance: any = {};

  mounted(): void {
    this.currentVesselId = parseInt(this.$route.params.vesselId);

    const param = {
      vesselId: this.currentVesselId,
    };

    this.instance = new koHtmlAndViewModel.viewModel(param);
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;

    ko.applyBindings(this.instance, this.$el);

    const configEmissionReport = configEmissionReportFactory.get();
    configEmissionReport.currentVessel = this.$store.getters["Vessels/currentVessel"];
    configEmissionReport.load();
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
