





















import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component
export default class FeatureNotAvailable extends Vue {
  vessel = Vessels.currentVessel;
  get vesselName(): string | boolean {
    if (!this.vessel) return false;
    return this.vessel.name;
  }

  get hasSlotData(): boolean {
    return Boolean(this.$slots.text);
  }
}
