







import { Component, Vue } from "vue-property-decorator";
import emissionReportWrapper from "@/components/knockoutWrappers/EmissionReportWrapper.vue";
import featureNotAvailable from "@/components/FeatureNotAvailable.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    emissionReportWrapper,
    featureNotAvailable,
  },
})
export default class EmissionReport extends Vue {
  vessel = Vessels.currentVessel;
  get featureEnabled(): boolean {
    if (!this.vessel) return false;
    return this.vessel.features.some(feature => feature.name === "EmissionReport");
  }
}
